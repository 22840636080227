import React, { useState } from "react"
import Layout from "../layout"
import SEO from "../components/SEO"
import Anelar from "../components/Configuradores/inputs/anelar.json"
import { generateAnelarImage } from "../components/Configuradores/imageUtils/anelar"
import { Configurador } from "../components/Configuradores/Configurador"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const ConfigAnelar = ({ location }) => {
  const { t } = useTranslation()

  const configuradorForm = t("configurador", { returnObjects: true })
  const userForm = t("form", { returnObjects: true })

  const [finalImg, setFinalImg] = useState("")

  const handleFinalImg = inputs => {
    let model = inputs["tipologia-porta"],
      doors = inputs["numero-portas-por-camaras"],
      chambers = inputs["numero-camaras"],
      width = inputs["largura-porta"],
      height = inputs["altura-camara"]

    setFinalImg(generateAnelarImage(model, doors, chambers, width, height))
  }

  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Anelar" bodyClass={"configurador"}
        domain={location?.hostname}></SEO>
      <Configurador
        configuradorForm={configuradorForm}
        inputsJson={Anelar}
        userForm={userForm}
        email={configuradorForm["email"]}
        oven={"Anelar"}
        finalImg={finalImg}
        handleFinalImg={handleFinalImg}
      />
    </Layout>
  )
}

export default ConfigAnelar

export const ConfiguradorAnelar = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["anelar", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
