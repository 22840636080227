export const generateAnelarImage = (model, doors, chambers, width, height) => {
  const doorWidth = width ? width.split("-")[1] : ""
  const numOfChambers = chambers ? chambers.split("-")[1] : ""

  switch (model) {
    case "anelar":
      if (doors === "2") {
        if (numOfChambers === "3") {
          if (doorWidth === "61cm") {
            switch (height) {
              case "22cm":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1622887705/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3cn_uatkim.svg"

              case "26cm":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1622887812/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3ca_x5ttrg.svg"

              case "29cm":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1622887860/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3sa_wcoy2i.svg"

              case "26cm-22cm":
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1622887684/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_2cn_1ca_axj7ks.svg"
            }
          } else if (doorWidth === "75cm") {
            if (height === "22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888211/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3cn_otjvgw.svg"
            } else if (height === "26cm-22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888193/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_2cn_1ca_fvbgwm.svg"
            }
          }
        } else if (numOfChambers === "4") {
          if (doorWidth === "61cm") {
            if (height === "22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888566/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_4cn_kopqqq.svg"
            } else if (height === "26cm-22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888541/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3cn_1ca_ifa0as.svg"
            }
          } else if (doorWidth === "75cm") {
            if (height === "22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888639/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_4cn_r84zgm.svg"
            } else if (height === "26cm-22cm") {
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888600/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_2P_3cn_1ca_uhdvt3.svg"
            }
          }
        }
      } else if (doors === "3") {
        if (numOfChambers === "3") {
          switch (height) {
            case "22cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888853/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_3cn_bwaqmt.svg"

            case "26cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888872/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_3ca_bewhv3.svg"

            case "29cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888889/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_3sa_y4zak7.svg"

            case "26cm-22cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622888904/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_2cn_ca_bnpdgi.svg"
          }
        } else if (numOfChambers === "4") {
          if (height === "22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889051/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_4cn_qozaf3.svg"
          } else if (height === "26cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889034/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_3P_3cn_1ca_trghsd.svg"
          }
        }
      } else if (doors === "4") {
        if (numOfChambers === "3") {
          switch (height) {
            case "22cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889139/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_3cn_nhsybd.svg"

            case "26cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889156/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_3ca_b9fnt1.svg"

            case "29cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889173/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_3sa_bimhph.svg"

            case "26cm-22cm":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889197/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_2cn_ca_mt0mra.svg"
          }
        } else if (numOfChambers === "4") {
          if (height === "22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889258/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_4cn_p3dsaq.svg"
          } else if (height === "26cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622889273/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelar_4P_3cn_1ca_vp2xxr.svg"
          }
        }
      }

    case "anelec":
      if (doors === "2") {
        if (numOfChambers === "3") {
          if (height === "19cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902741/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_2P_3cn_eq8gfq.svg"
          } else if (height === "24cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902729/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_2P_2cn_1ca_e7vg5v.svg"
          }
        } else if (numOfChambers === "4") {
          if (height === "19cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902829/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_2P_3cn_1cn_e_ist47i.svg"
          } else if (height === "24cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902816/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_2P_3cn_1ca_e_ekw8px.svg"
          }
        }
      } else if (doors === "3") {
        if (numOfChambers === "3") {
          if (height === "19cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902971/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_3P_2cn_1cn_e_obdqif.svg"
          } else if (height === "24cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622902959/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_3P_2cn_1ca_e_h7g55t.svg"
          }
        } else if (numOfChambers === "4") {
          if (height === "19cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903046/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_3P_3cn_1cn_e_x9f40o.svg"
          } else if (height === "24cm-22cm") {
            return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903029/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Anelec_61_3P_3cn-1ca_e_fsqdfo.svg"
          }
        }
      }

    case "modulec":
      if (numOfChambers === "3") {
        if (height === "26cm-24cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903405/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulec_61_2P_2cn_1cn_e_irlsyc.svg"
        } else if (height === "31cm-24cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903392/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulec_61_2P_2cn_1ca_e_aecjyd.svg"
        }
      } else if (numOfChambers === "4") {
        if (height === "19cm-22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903529/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulec_61_2P_3cn_1cn_e_rr5jaj.svg"
        } else if (height === "24cm-22cm") {
          return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903517/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulec_61_2P_3cn_1ca_e_t3fx3p.svg"
        }
      }

    case "modulgaz":
      if (numOfChambers === "3") {
        return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903777/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulgaz_45_2P_3cn_mqchoy.svg"
      } else if (numOfChambers === "4") {
        return "https://res.cloudinary.com/de6biukqo/image/upload/v1622903886/Configurador-Anelar/finais/ilustra_%C3%A4es_Gama_Anelar_Modulgaz_45_2P_4cn_lzuplj.svg"
      }
  }
}
